<template>
  <div id="subject">
    <!-- 专题详情 -->
    <div class="banner">
      <div class="con">
        <router-link v-if="subject.thumbs" :to="{path:'/2022sh/teacher',name:'TwoSHTeacher',query:{id:subject.producerId}}">
          <div class="img" :style="'background:url(' + getUrl(subject.thumbs) +') no-repeat center center;'"></div>
        </router-link>
        <div class="img" v-if="!subject.thumbs" style="background:url(http://vip.msup.com.cn/index.php/xin-bin-api/head?url=https%3A%2F%2Ff.msup.com.cn%2Fhead.jpeg&size=600x600) no-repeat center center;"></div>
        <div class="text">
          <!-- <p class="title">专题出品人：<span>{{(subject.name)?(subject.name):"敬请期待"}}</span></p> -->
          <p class="title">专题出品人：<span>{{(subject.name)?(name):"敬请期待"}}</span></p>
          <p class="desc">{{subject.position}}</p>
          <p class="desc">{{subject.ldesc}}</p>
        </div>
      </div>
    </div>
    <div class="banner_bottom">
      <div class=con>
        <div class="text">
          <p>专题：{{subject.venueName}}</p>
          <!-- <p>{{subject.ldesc}}</p> -->
        </div>
        <div class="clearboth"></div>
      </div>
    </div>
    <!--  -->
    <div class="sub">
      <!-- mob  -->
      <div class="con_top mob">本专题下的议题</div>
      <div class="con mob">
        <div v-for="(item,index) in subject.courses" :key="'course_item'+index" :class="'course course_'+index">
          <div class="left top" @click="item.showMob=!item.showMob,showDetail(item.showMob,index)">
            <div class="text">
              <!-- <router-link :to="{path:'/2022sh/course',name:'TwoSHCourse',query:{id:item.courseid}}"> -->
                <h1>{{item.courseTitle}}</h1>
                <p>by <span>{{item.name}}</span></p>
                <p>{{item.company}}&nbsp;&nbsp;&nbsp;{{item.position}}</p>
              <!-- </router-link> -->
            </div>
          </div>
          <div class="right bottom" style="visibility:hidden;max-height:0;padding-bottom:0;">
           <!-- v-if="item.showMob" -->
            <!-- <p>描述</p> -->
            <h2>课程概要</h2>
            <p>{{item.desc}}</p>
            <h2>听众收益</h2>
            <p>{{item.profit}}</p>
          </div>
        </div>
      </div>
      <!-- pc -->
      <div class="con pc">
        <div class="course" v-for="(item,index) in subject.courses" :key="'course_item'+index">
          <div class="left">
            <div class="text">
              <p>by <span>{{item.name}}</span></p>
              <p>{{item.company}}<br>{{item.position}}</p>
            </div>
          </div>
          <div class="right">
            <h1>
              <router-link :to="{path:'/2022sh/course',name:'TwoSHCourse',query:{id:item.courseid}}">{{item.courseTitle}}</router-link>
            </h1>
            <!-- <p>描述</p> -->
            <h2>课程概要</h2>
            <p>{{item.desc}}</p>
            <h2>听众收益</h2>
            <p>{{item.profit}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="other">
      <div class="con">
        <h1>相关专题</h1>
        <!-- <h1>相关案例</h1> -->
        <div class="case">
          <ul>
          <!-- <router-link v-for="(item,index) in subject.venues" :key="'sub_venues'+index" 
            :to="{path:'/2022sh/subject',name:'TwoSHSubject',query:{id:item.id}}">
            <div class="content">
              <img src="../../public/img/logo.png" alt="" />
              <p class="name">{{item.producers?item.producers:'敬请期待'}}</p>
              <p class="company">{{item.company}}<br />{{item.position}}</p>
            </div>
            <p class="case-title">
              {{item.venueName}}
            </p>
          </router-link> -->
          </ul>
        </div>
        <div class="clearboth"></div>
        <div class="bot"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import {sid, getLecturerInfo} from "@/utils/api.js";

export default {
  name:"Subject",
  data(){
    return {
      params:'',
      subject:'',
      name:'',
      // show:[]
    }
  },
  methods:{
    showDetail: function(b,i){
      // console.log(this.subject.courses[i],this.subject.courses[i].showMob);
      // console.log(e);
      // for(let s=0;s<$(".con.mob .course").length;s++){
      //   this.show[s] = false;
      // }
      // this.show[i] = !this.show[i];
      // console.log($(".con.mob .course_"+i+" .bottom").css);
      // $(".con.mob .course_"+i+" .bottom").css = "display:block;"
      if(b){
        $(".con.mob .course_"+i+" .top").css({'background':'#dfe2eb','border-color':'#999999'});
        $(".con.mob .course_"+i+" .bottom").css({'visibility':'visible','max-height':'2000px','padding-bottom':'35px'});
        $(".con.mob .course_"+i+" .bottom *").css({'visibility':'visible'});
      } else {
        $(".con.mob .course_"+i+" .top").css({'background':'#ffffff','border-color':'#003177'});
        $(".con.mob .course_"+i+" .bottom").css({'visibility':'hidden','max-height':'0','padding-bottom':'0'});
        $(".con.mob .course_"+i+" .bottom *").css({'visibility':'hidden'});
      }
    },
    getUrl: function(str){
      // "[{"fileUrl":"https:\/\/f.msup.com.cn\/loEV_IvMHXqpRB8xxWfUFcN8DPI3.jpg",
      //    "thumbnailUrl":"https:\/\/f.msup.com.cn\/loEV_IvMHXqpRB8xxWfUFcN8DPI3.jpg?imageMogr2\/crop\/!2765x2765a346a922"}]"
      return str.split('fileUrl":"')[1].split('","')[0];
    },
    getSubject: function(){
      // console.log(this.params.id);
      var url = "https://api.msup.com.cn/group/group-project-info/" + this.params.id;
      // var _this = this;
      this.$http
        .jsonp(url)
        .then(data => {
          return data.json();
        })
        .then(data => {
          if(data.errno == 0){
            // console.log("Subject data: ",data.data);
            this.subject = data.data;
            for(let s=0;s<data.data.courses.length;s++){
            //   this.show[s] = false;
              this.subject.courses[s].showMob = false;
            }
            // 出品人name -》 嘉宾name
            if(data.data.producerId){
              this.$http
                .jsonp(`${getLecturerInfo}/${sid(this.global.year)}/${data.data.producerId}`)
                .then((res) => {
                  return res.json();
                })
                .then((res) => {
                  if(res.errno == 0){
                    this.name = res.data.name;

                    // 相关专题 -》 专题 题目、出品人、公司、职位
                    // $(".other .case ul").remove();
                    for(let v=0;v<data.data.venues.length;v++){
                      // 
                      this.subject.venues[v].company = '敬请期待';
                      this.$http
                        .jsonp(`https://api.msup.com.cn/group/group-project-info/${data.data.venues[v].id}`)
                        .then((tres) => {
                          return tres.json();
                        })
                        .then((tres) => {
                          if(tres.errno == 0){
                            this.subject.venues[v].company = 'new';
                            // console.log("Subject sub: ",this.subject);

                            let style_a = "cursor: pointer;display: flex;flex-direction: column-reverse;padding: 22px 22px 21px 20px;margin: 12.5px;text-decoration: none;text-align: left;background: #ffffff;border: 1px solid #d6dce3;";
                            if( (v+1)%3==0 ){
                              style_a += "margin-right: 0;"
                            }
                            let style_div = "border-top: 1px solid #d6dce3;padding-top: 36px;padding-left: 9px;width: 340px;";
                            let style_p1 = "font-size: 18px;font-family: PingFang SC;font-weight: bold;color: #484848;margin-bottom: 6px;";
                            let style_p2 = "font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;color: #3e3a39;line-height: 16px;";
                            let style_p3 = "width: 305px;padding-left: 7px;padding-bottom: 10px;line-height: 25px;font-weight: 500;font-size: 18px;font-family: PingFang SC;color: #003177;";
                            // let style_mob = "@media(max-width:750px){a{width: 90%;padding: 13px!important;} p{font-size: 13px!important;}}"
                            $(".other .case ul").append(`
                              <a style="${style_a}" href="/subject?id=${data.data.venues[v].id}">
                                <div class="content" style="${style_div}">
                                  <p class="name" style="${style_p1}">
                                    ${this.subject.venues[v].producers?this.subject.venues[v].producers:'敬请期待'}
                                  </p>
                                  <p class="company" style="${style_p2}">
                                    ${tres.data.company?tres.data.company:'敬请期待'} <br> ${tres.data.position?tres.data.position:'敬请期待'}
                                  </p>
                                </div>
                                <p class="case-title" style="${style_p3}">${this.subject.venues[v].venueName}</p>
                              </a>
                            `);
                          }
                        });
                    }

                  }
                });
            } else {
              // 相关专题 -》 专题 题目、出品人、公司、职位
              // $(".other .case ul").remove();
              for(let v=0;v<data.data.venues.length;v++){
                // 
                this.subject.venues[v].company = '敬请期待';
                this.$http
                  .jsonp(`https://api.msup.com.cn/group/group-project-info/${data.data.venues[v].id}`)
                  .then((tres) => {
                    return tres.json();
                    })
                  .then((tres) => {
                    if(tres.errno == 0){
                      this.subject.venues[v].company = 'new';
                      // console.log("Subject sub: ",this.subject);
                      let style_a = "cursor: pointer;display: flex;flex-direction: column-reverse;padding: 22px 22px 21px 20px;margin: 12.5px;text-decoration: none;text-align: left;background: #ffffff;border: 1px solid #d6dce3;";
                      if( (v+1)%3==0 ){
                        style_a += "margin-right: 0;"
                      }
                      let style_div = "border-top: 1px solid #d6dce3;padding-top: 36px;padding-left: 9px;width: 340px;";
                      let style_p1 = "font-size: 18px;font-family: PingFang SC;font-weight: bold;color: #484848;margin-bottom: 6px;";
                      let style_p2 = "font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;color: #3e3a39;line-height: 16px;";
                      let style_p3 = "width: 305px;padding-left: 7px;padding-bottom: 10px;line-height: 25px;font-weight: 500;font-size: 18px;font-family: PingFang SC;color: #003177;";
                      // let style_mob = "@media(max-width:750px){a{width: 90%;padding: 13px!important;} p{font-size: 13px!important;}}"
                      $(".other .case ul").append(`
                        <a style="${style_a}" href="/subject?id=${data.data.venues[v].id}">
                          <div class="content" style="${style_div}">
                            <p class="name" style="${style_p1}">
                              ${this.subject.venues[v].producers?this.subject.venues[v].producers:'敬请期待'}
                            </p>
                            <p class="company" style="${style_p2}">
                              ${tres.data.company?tres.data.company:'敬请期待'} <br> ${tres.data.position?tres.data.position:'敬请期待'}
                            </p>
                          </div>
                          <p class="case-title" style="${style_p3}">${this.subject.venues[v].venueName}</p>
                        </a>
                      `);
                    }
                  });
              }
            }
          }
        })
    }
  },
  created(){
    this.params = this.$route.query;
    this.getSubject();
    // for(let s=0;s<$(".con.mob .course").length;s++){
    //   this.show[s] = false;
    // }
  },
  moubted(){
    // this.getSubject();
  },
  watch: {
    '$route' (to, from) { //监听路由是否变化
      if(to.query.id != from.query.id){
        this.params = this.$route.query;
        this.getSubject();
      }
    }
  },
}
</script>

<style lang="scss">
*{
  font-size: 14px;
  text-align: left;
  margin: 0;
  padding: 0;
}
a,
a:hover,
a:visited {
  color: #337ab7;;
  text-decoration: none;
}
// div
h1,h2,h3,h4,h5,h6,p,a,span,b,i,em,div {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}
.pc{
  display: block;
}
.mob{
  display: none;
}

#subject {
  // margin-top: 135px;
  text-align: left;
  .banner {
    // margin-top: 135px;
    width: 100%;
    min-height: 305px;
    background: #fafbfc;
    text-align: center;
    .con{
      max-width: 1110px;
      margin: 0 auto;
      padding: 43px 0;
      .img{
        width: 193px;
        height: 211px;
        margin-right: 60px;
        float: left;
        border-radius: 7px;
        background-size: 100% 100%!important;
      }
      .text{
        padding-top: 20px;
        .title,.title span{
          font-size: 36px;
          font-family: PingFang SC Bold;
          font-weight: bold;
          color: #003177;
          line-height: 22px;
          span{
            font-weight: 400;
            color: #212121;
          }
        }
        .title span{
          font-family: PingFang SC Regular;
        }
        .desc{
          font-size: 16px;
          font-family: PingFang SC Regular;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
        }
        .desc:nth-of-type(2){
          padding: 22px 0;
          margin-top: 5px;
        }
        .desc:nth-of-type(3){
          line-height: 30px;
        }
      }
    }
  }
  .banner_bottom{
    width: 100%;
    // min-height: 357px;
    background: #ffffff;
    .con{
      max-width: 1142px;
      margin: 0 auto;
      .text{
        max-width: 790px;
        margin-left: 330px;
        padding-top: 85px;
        padding-bottom: 85px;
        p{
          font-size: 16px;
          font-family: PingFang SC Regular;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
        }
        p:first-of-type{
          font-size: 36px;
          color: #505050;
          padding-bottom: 60px;
        }
      }
    }
  }
  .sub{
    width: 100%;
    background: #fafbfc;
    padding-top: 96px;
    padding-bottom: 64px;
    .con_top{
      width: 100%;
      padding: 15px 0;
      color: #ffffff;
      background: #293F76;
      font-size: 15px;
      font-family: PingFang SC Bold;
      font-weight: bold;
      text-align: center;
    }
    .con.mob{
      .course{
        border: none;
        .left.top{
          border-left: 8px solid #003177;
          -webkit-transition: all 1.2s;
          -moz-transition: all 1.2s;
          -o-transition: all 1.2s;
          transition: all 1.2s;
          display: block;
          width: 100%!important;
          margin: 0;
          padding: 32px 5%;
          .text{
            h1{
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #1F64AF;
              line-height: 22px;
              padding-bottom: 18px;
            }
            p,p *{
              font-size: 13px!important;
              line-height: 14px;
              white-space: pre-wrap;
            }
            p:nth-of-type(1){
              padding-bottom: 13px;
            }
          }
        }
        .bottom{
          border-left: 8px solid #003177;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;
          width: 100%!important;
          max-height: 0;
          overflow: hidden;
          margin: 0;
          padding: 32px 5%;
          padding-top: 0px;
          *{
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
          }
          h2{
            font-size: 16px;
            padding-bottom: 20px;
          }
          p{
            font-size: 15px;
          }
        }
      }
    }
    .con.pc{
      .course{
        display: flex!important;
      }
    }
    .con{
      max-width: 1142px;
      margin: 0 auto;
      .course{
        width: 100%;
        background: #ffffff;
        border-left: 8px solid #003177;
        margin-bottom: 40px;
        .left{
          flex: none;
          width: 240px;
          align-items:center;
          display: flex;
          display: -webkit-flex;
          margin-left: 37px;
          margin-right: 45px;
          .text{
            margin: auto 0;
            p{
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 24px;
            }
            p:nth-of-type(1),p:nth-of-type(1) *{
              font-size: 18px;
              font-weight: 500;
              line-height: 29px;
              color: #131313;
              padding-bottom: 10px;
            }
            p:nth-of-type(1) span{
              color: #1F64AF;
            }
          }
        }
        .right{
          padding-top: 56px;
          padding-bottom: 53px;
          margin-right: 30px;
          h1,h1 *{
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #1F64AF;
            line-height: 32px;
            padding-bottom: 45px;
            cursor: pointer;
          }
          h2{
            font-size: 18px;
            font-family: PingFang SC Medium;
            font-weight: 500;
            color: #131313;
            line-height: 29px;
            padding: 25px 0;
          }
          p{
            white-space: pre-line;
            font-size: 16px;
            font-family: PingFang SC Regular;
            font-weight: 400;
            color: #999999;
            line-height: 30px;
          }
        }
      }
    }
  }
  .other{
    width: 100%;
    background: #ffffff;
    padding-top: 86px;
    .con{
      max-width: 1225px;
      margin: 0 auto;
      h1{
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #003177;
        padding-bottom: 50.5px;
      }
      .case{
        max-width: 1225px;
        margin: 0 auto;
        ul{
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 43px;
          margin-bottom: 0;
          a:hover {
            box-shadow: 0px 0px 8px 0px rgba(72, 75, 77, 0.1);
          }
          a {
            display: flex;
            flex-direction: column-reverse;
            padding: 22px 22px 21px 20px;
            margin: 12.5px;
            text-decoration: none;
            text-align: left;
            background: #ffffff;
            border: 1px solid #d6dce3;
            .case-title {
              width: 305px;
              padding-left: 7px;
              padding-bottom: 10px;
              line-height: 25px;
              font-weight: 500;
              font-size: 18px;
              font-family: PingFang SC;
              color: #003177;
            }
            .content {
              border-top: 1px solid #d6dce3;
              padding-top: 36px;
              padding-left: 9px;
              width: 340px;
              img {
                width: 69px;
                height: 13px;
              }
              .name {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #484848;
                margin-bottom: 6px;
              }
              .company {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                color: #3e3a39;
                line-height: 16px;
              }
            }
          }
          a:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      .bot{
        max-width: 1200px;
        margin: 0 12.5px;
        height: 102.5px;
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }
}


.clearboth {
  clear: both;
  float: none !important;
  width: 0 !important;
  height: 0 !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}


// @media (max-width: 1023px) {
//   #subject {
//     // margin-top: 86px!important;
//     .banner {
//       // margin-top: 86px!important;
//     }
//   }
// }
@media (max-width: 1200px) {
  #subject {
    .banner{
      height: auto!important;
      .con{
        width: 90%;
        .text{
          .title{
            line-height: 42px!important;
          }
        }
      }
    }
    .banner_bottom{
      height: auto!important;
      .con{
        width: 90%;
      }
    }
    .sub{
      .con{
        width: 90%;
      }
    }
  }
}

@media (max-width: 750px) {
  #subject {
    .pc{
      display: none!important;
    }
    .mob{
      display: block!important;
    }
    .banner{
      height: auto!important;
      .con{
        width: 85%;
        .img{
          width: 32vw!important;
          height: 34vw!important;
          max-width: 135px!important;
          max-height: 145px!important;
          float: none!important;
          margin: 0!important;
          margin: 0 auto!important;
        }
        .text{
          padding-top: 25px!important;
          .title,.title *{
            font-size: 15px!important;
          }
          .title{
            line-height: 22px!important;
            text-align: center;
          }
          .desc{
            font-size: 13px!important;
            line-height: 24px!important;
            color: #4c4c4c!important;
          }
          .desc:nth-of-type(2){
            padding-top: 5px!important;
            padding-bottom: 16px!important;
            text-align: center!important;
            color: #293F76!important;
            font-family: PingFang SC Medium!important;
            font-weight: 500!important;
            margin: 0!important;
          }
        }
      }
    }
    .banner_bottom{
      // min-height: 0px;
      .con{
        width: 85%!important;
        .text{
          padding-top: 45px!important;
          padding-bottom: 45px!important;
          margin-left: 0!important;
          p{
            font-size: 15px!important;
            line-height: 23px!important;
          }
          p:first-of-type{
            font-size: 18px!important;
            line-height: 22px!important;
            padding-bottom: 24px!important;
          }
        }
      }
    }
    .sub{
      padding: 0!important;
      .con{
        padding-top: 35px;
        padding-bottom: 15px!important;
        .course{
          margin-bottom: 15px!important;
        }
      }
    }
    .other{
      padding-top: 45px!important;
      .con{
        .bot{
          height: 35px!important;
        }
        h1{
          padding-bottom: 24px!important;
          font-size: 18px!important;
        }
        .case{
          *{
            font-size: 15px!important;;
          }
          ul{
            a{
              width: 90%;
              padding: 13px!important;
              .content{
                width: 100%!important;
                padding-top: 18px!important;
                img{
                  width: 60px!important;
                  height: 12px!important;
                }
                p{
                  font-size: 13px!important;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 540px) { }
</style>